import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useRouter, NextRouter } from 'next/router'
import { useDebouncedCallback } from 'use-debounce'
import { ParsedUrlQuery } from 'querystring'
import {} from 'src/components/designsystem'
import { logger } from 'src/utils'
import { trackEvent } from 'src/utils/analytics'
import {
  FiltersProps,
  isDateFilterOptionItem,
  isRangeFilterOptionItem,
  isTextboxFilterOptionItem,
  isDynamicFilterOptionItem,
  isFilterOptionCollection,
  isFilterOptionItem,
  isStringArray,
} from 'src/utils/filters'
import { base64DecodeString, base64EncodeString } from 'src/utils/string/base64'

const DEFAULT_FILTER_QUERY_DEBOUNCE_MS = 1000

interface useFiltersArgs<FilterOptions> {
  acceptedFilterKeys: string[]
  debounceMs?: number
  filterOptions?: FilterOptions
  defaultFilters?: FilterOptions
}

export function useFilters<FilterOptions, FiltersForQuery>({
  acceptedFilterKeys,
  debounceMs = DEFAULT_FILTER_QUERY_DEBOUNCE_MS,
  filterOptions = {} as FilterOptions,
  defaultFilters = {} as FilterOptions,
}: useFiltersArgs<FilterOptions>) {
  const router = useRouter()
  const { asPath, query } = router
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const parsedUrlFilters = parseURLFilters<FilterOptions>(acceptedFilterKeys, query)

    if (!defaultFilters || Object.keys(defaultFilters).length === 0) {
      return parsedUrlFilters
    }

    return {
      ...defaultFilters,
      ...parsedUrlFilters,
    }
  })

  const [debouncedFilters, _setDebouncedFilters] = useState(selectedFilters)
  const setDebouncedFilters = useDebouncedCallback(_setDebouncedFilters, debounceMs)

  const isFiltersValidated = useRef(false)

  useEffect(() => {
    if (Object.keys(filterOptions).length === 0 || isFiltersValidated.current) return

    const validatedFilters = validateFilters<FilterOptions>({ selectedFilters, filterOptions })

    setSelectedFilters(validatedFilters)
    setDebouncedFilters(validatedFilters)

    isFiltersValidated.current = true
  }, [selectedFilters, filterOptions, setDebouncedFilters])

  const filterForQuery = useMemo(
    () =>
      debouncedFilters
        ? convertSelectedFilters<FilterOptions, FiltersForQuery>(debouncedFilters)
        : ({} as FiltersForQuery),
    [debouncedFilters]
  )
  const isFiltered = Object.keys(filterForQuery).length > 0

  const setAllSelectedFilters = useCallback<FiltersProps<FilterOptions>['setAllSelectedFilters']>(
    (selected) => {
      if (
        selected &&
        Object.keys(selected)
          .map((k) => selected[k])
          .filter((prop) => prop !== undefined).length > 0
      ) {
        setSelectedFilters(selected)
        setDebouncedFilters(selected)
        return
      }

      setSelectedFilters({} as FilterOptions)
      // Clearing all should not be debounced
      _setDebouncedFilters({} as FilterOptions)
      // Ensure any pending debounce calls are canceled
      setDebouncedFilters.cancel()
    },
    [setDebouncedFilters]
  )

  const setFilterById = useCallback<FiltersProps<FilterOptions>['setFilterById']>(
    (filterId, values, trackingDimensions) => {
      trackEvent(...trackingDimensions)
      if (
        (isFilterOptionItem(values) && values?.length > 0) ||
        (isFilterOptionCollection(values) && values?.length > 0) ||
        (isDateFilterOptionItem(values) && (values.from || values.to)) ||
        (isRangeFilterOptionItem(values) && (values.low || values.high)) ||
        (isTextboxFilterOptionItem(values) && values.value) ||
        isStringArray(values) ||
        (isDynamicFilterOptionItem(values) &&
          values.flatMap((val) => val.options).filter((val) => val !== null).length > 0)
      ) {
        setAllSelectedFilters({ ...selectedFilters, [filterId]: values })
      } else {
        // we can't verify it has value, so delete it.
        const newSelected = { ...selectedFilters }
        delete newSelected[filterId]
        setAllSelectedFilters(newSelected)
      }
    },
    [selectedFilters, setAllSelectedFilters]
  )

  useEffect(() => {
    const path = asPath.slice(0, asPath.indexOf('?') === -1 ? undefined : asPath.indexOf('?'))

    if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
      if (path !== asPath) {
        replaceRoute(router, path, {
          sort: query?.sort,
        })
      }
      return
    }

    replaceRoute(router, path, {
      filter: base64EncodeString(JSON.stringify(selectedFilters)),
      sort: query?.sort,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  return {
    filterForQuery,
    isFiltered,
    selectedFilters,
    setAllSelectedFilters,
    setFilterById,
    isFiltersValidated: isFiltersValidated.current,
  }
}

export function validateFilters<FilterOptions>({ selectedFilters, filterOptions }) {
  return Object.keys(selectedFilters)
    .map((f) => ({
      filterKey: f,
      selectedValue: selectedFilters[f],
    }))
    .reduce((acc, { filterKey, selectedValue }) => {
      const filteredKeyValue = {}

      // [ { "id": "opt-001", "name": "Option 1" }, ... ]
      if (isFilterOptionItem(selectedValue)) {
        const validatedFilterOption = validateFilterOptionItem({
          selectedValue,
          filterOptions,
          filterKey,
        })

        if (validatedFilterOption.length > 0) filteredKeyValue[filterKey] = validatedFilterOption
      }

      // [ { "name": "Option A", ids: ["option-a-001", "option-a-002"] }, ... ]
      if (isFilterOptionCollection(selectedValue)) {
        const validatedFilterOption = validateFilterOptionCollection({
          selectedValue,
          filterOptions,
          filterKey,
        })

        if (validatedFilterOption.length > 0) filteredKeyValue[filterKey] = validatedFilterOption
      }

      // [ "Option A", "Option B", "Option C", ... ]
      if (isStringArray(selectedValue)) {
        const validatedFilterOption = validateFilterStringArray({
          selectedValue,
          filterOptions,
          filterKey,
        })

        if (validatedFilterOption.length > 0) filteredKeyValue[filterKey] = validatedFilterOption
      }

      // { "from": "2023-07-30T12:00:00-06:00", "to": "2023-08-29T12:00:00-06:00" }
      if (isDateFilterOptionItem(selectedValue)) {
        filteredKeyValue[filterKey] = selectedValue
      }

      // { "value": "search term" }
      if (isTextboxFilterOptionItem(selectedValue)) {
        filteredKeyValue[filterKey] = selectedValue
      }

      // { "low": "100", "high": "1000" }
      if (isRangeFilterOptionItem(selectedValue)) {
        filteredKeyValue[filterKey] = selectedValue
      }

      // [ { "id": "Field", "name": "Field", "options": [ { "id": "Field 1", "name": "Field 1" }, ... ], "type": "multiple" } ]
      if (isDynamicFilterOptionItem(selectedValue)) {
        const validatedSelectedFilter = validateDynamicFilterOptionItem({
          selectedValue,
          filterOptions,
          filterKey,
        })

        if (validatedSelectedFilter.length > 0) {
          filteredKeyValue[filterKey] = validatedSelectedFilter
        }
      }

      return { ...acc, ...filteredKeyValue }
    }, {} as FilterOptions)
}

function validateFilterOptionItem({ selectedValue, filterOptions, filterKey }) {
  return selectedValue.filter((selectedItem) =>
    filterOptions[filterKey]?.some(
      (optionItem) => optionItem.name === selectedItem.name && optionItem.id === selectedItem.id
    )
  )
}

function validateFilterOptionCollection({ selectedValue, filterOptions, filterKey }) {
  return selectedValue.filter((selectedItem) =>
    filterOptions[filterKey]?.some(
      (optionItem) =>
        optionItem.name === selectedItem.name &&
        optionItem.ids.every((i) => selectedItem.ids.includes(i)) &&
        optionItem.ids.length === selectedItem.ids.length
    )
  )
}

function validateFilterStringArray({ selectedValue, filterOptions, filterKey }) {
  return selectedValue.filter((selectedItem) => filterOptions[filterKey]?.includes(selectedItem))
}

function validateDynamicFilterOptionItem({ selectedValue, filterOptions, filterKey }) {
  return selectedValue.reduce((acc, selectedItem) => {
    const filterOptionObject = filterOptions[filterKey].find((i) => i.id === selectedItem.id)

    const validSelectedDynamicFilters = selectedItem.options.filter((selectedObject) =>
      filterOptionObject.options.some(
        (optionItem) =>
          optionItem.id === selectedObject.id && optionItem.name === selectedObject.name
      )
    )

    const validatedDynamicFilter = {
      ...selectedItem,
      options: validSelectedDynamicFilters,
    }

    const isValid =
      filterOptions[filterKey].some(
        (optionItem) =>
          optionItem.id === selectedItem.id &&
          optionItem.name === selectedItem.name &&
          optionItem.type === selectedItem.type
      ) && validatedDynamicFilter.options.length > 0

    if (!isValid) return [...acc]
    return [...acc, validatedDynamicFilter]
  }, [])
}

export function convertSelectedFilters<FilterOptions, FiltersForQuery>(
  selectedFilters: FilterOptions
): FiltersForQuery {
  return Object.keys(selectedFilters)
    .map((f) => ({ filterKey: f, selectedValue: selectedFilters[f] }))
    .reduce<FiltersForQuery>((filterRequest, { filterKey, selectedValue }) => {
      // easy out to simplify if statements
      if (!selectedValue) return filterRequest

      const newKeyValue = {}

      if (isFilterOptionItem(selectedValue)) {
        newKeyValue[filterKey] = selectedValue.flatMap((option) => option.id)
      }
      if (isFilterOptionCollection(selectedValue)) {
        newKeyValue[filterKey] = selectedValue.flatMap((option) => option.ids)
      }
      if (isDateFilterOptionItem(selectedValue) || isStringArray(selectedValue)) {
        newKeyValue[filterKey] = selectedValue
      }
      if (isRangeFilterOptionItem(selectedValue) || isStringArray(selectedValue)) {
        newKeyValue[filterKey] = selectedValue
      }
      if (isTextboxFilterOptionItem(selectedValue) || isStringArray(selectedValue)) {
        newKeyValue[filterKey] = selectedValue
      }
      if (isDynamicFilterOptionItem(selectedValue)) {
        newKeyValue[filterKey] = selectedValue.map((addendum) => {
          return { id: addendum.id, options: addendum?.options?.map((option) => option.id) }
        })
      }

      return {
        ...filterRequest,
        ...newKeyValue,
      }
    }, {} as FiltersForQuery)
}

export function parseURLFilters<FilterOptions>(
  acceptedFilterKeys: string[],
  query: ParsedUrlQuery
): FilterOptions {
  if (!query?.filter || typeof query.filter !== 'string') return {} as FilterOptions

  try {
    const filtersFromQuery = parseBase64Object(query.filter)
    return acceptedFilterKeys.reduce<FilterOptions>(
      (acc, filterId) => ({
        ...acc,
        [filterId]: filtersFromQuery[filterId],
      }),
      {} as FilterOptions
    )
  } catch (e) {
    //catching things like bad json in encoded url filters
    logger.error({ message: 'Error in parseURLFilters', context: e })
    return {} as FilterOptions
  }
}

function replaceRoute(router: NextRouter, pathname: string, query = {}) {
  router.replace({ pathname, query }, undefined, { shallow: true })
}

function parseBase64Object(encodedFilter: string) {
  return JSON.parse(base64DecodeString(encodedFilter))
}
